import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { ApplicationInterview,
         AvailableCustomerPerTimeslot,
         HiddenFromCoustomer,
         IInterview,
         InterviewContent,
         InterviewStatus,
         TimeSlot } from '../model/interview.interface';
import { UserService } from './user.service';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { dateToUTCString } from '../resources/shared-functions';
import { VideoService } from './video/video.service';
import { BaseVideoUtilsService } from './base-video-utils.service';
import { ErrorHandlingService } from './handle-error.service';
import { SetupService } from './setup.service';
import { Roles } from '../model/role.interface';
import { Router } from '@angular/router';
import { FileResponse, IFile } from '../model/upload-files.model';
import { Email } from '../classes/email.class';

@Injectable({ providedIn: 'root' })
export class InterviewService {
  private _interviewsInThePast: IInterview[] = [];
  private _inrerviewsInThePast$: Subject<IInterview[]> = new Subject<IInterview[]>();
  private _apiServerUrl = `https://${environment.mediaServerUrl}`;

  get interviewsInThePast(): Observable<IInterview[]> {
    return this._inrerviewsInThePast$.asObservable();
  }

  get isCustomerGroupInterview(): boolean {
    return this.router.url.includes('customer-group-video-interview');
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private videoService: VideoService,
    private baseVideoService: BaseVideoUtilsService,
    private errorHandlingService: ErrorHandlingService,
    private setupService: SetupService,
    private router: Router
  ) {}

  createVideoInterview(interviewContent: InterviewContent, applicationId: number, uploadedFiles: FileResponse[], templateFiles: IFile[]): Observable<IInterview> {
    const newInterview: ApplicationInterview = {
      subject: interviewContent.subject,
      introForEmailInvitation: interviewContent.introForEmailInvitation,
      timeSlots: [],
      application: applicationId,
      attachments: Email.setAttachments(uploadedFiles, templateFiles),
      greeting: interviewContent.greeting || null
    };

    interviewContent.timeSlots.forEach(timeSlot => {
      newInterview.timeSlots.push({
        start: dateToUTCString(timeSlot.start),
        end: dateToUTCString(timeSlot.end)
      });
    });

    return this.http.post<IInterview>(`${environment.videoInterview}`, newInterview);
  }

  getVideoInterviewsForCustomer(interviewStatus?: InterviewStatus, period?: {startDate: Date, endDate: Date}): Observable<IInterview[]> {
    let params = new HttpParams();

    if (interviewStatus) {
      params = params.append('status', interviewStatus);
    }

    if (period && period.startDate) {
      params = params.append('startDate', dateToUTCString(period.startDate));
      params = params.append('endDate', dateToUTCString(period.endDate));
    }

    const userId = this.userService.userId;
    const role = this.userService.role;
    const endpoint = ENTERPRISE_ROLES.includes(role) ? environment.enterpriseUsers : environment.customers;
    return this.http.get<IInterview[]>(`${endpoint}/${userId}/video_interviews`, {params})
      .pipe(
        map((interviewList: IInterview[]) => this.manageInteviewList(interviewList))
      );
  }

  getVideoInterviewsPerApplication(guid: string): Observable<IInterview[]> {
    return this.http.get<IInterview[]>(`${environment.applications}/${guid}/video_interviews`)
      .pipe(
        map((interviewList: IInterview[]) => this.manageInteviewList(interviewList))
      );
  }

  manageInteviewList(interviewList: IInterview[]): IInterview[] {
    interviewList.map(interview => {
      this.manageInterview(interview);
    });
    return interviewList;
  }

  manageInterview(interview: IInterview): IInterview {
    interview.timeSlots.forEach(timeSlot => {
      timeSlot.end = new Date(timeSlot.end);
      timeSlot.start = new Date(timeSlot.start);
    });
    if (interview.timeSlot) {
      interview.timeSlot.start = interview.timeSlot.start ? new Date(interview.timeSlot.start) : null;
      interview.timeSlot.end = interview.timeSlot.end ? new Date(interview.timeSlot.end) : null;
    }
    return interview;
  }

  getVideoInterview(guid: string): Observable<IInterview> {
    return this.http.get<IInterview>(`${environment.videoInterviews}/${guid}`)
      .pipe(
        map((interview: IInterview) => this.manageInterview(interview))
      );
  }

  cancelInterview(infoForCancellation: string, guid: string): Observable<string> {
    return this.http.post<string>(`${environment.videoInterview}/${guid}/cancel`, {infoForCancellation});
  }

  candidateConfirmInterview(time: {start: Date, end: Date}, guid: string): Observable<any> {
    const timeSlot = {
      start: time.start.toISOString().replace('T', ' ').replace(':00.000Z', 'UTC'),
      end: time.end.toISOString().replace('T', ' ').replace(':00.000Z', 'UTC')
    };
    return this.http.post(`${environment.videoInterview}/${guid}/confirm_time`, timeSlot);
  }

  addVideoRecording(guid: string, videoRecording: string): Observable<string> {
    return this.http.post<string>(`${environment.videoInterview}/${guid}/add_video_recording`, {videoRecording});
  }

  checkAvailability(guid: string, time: TimeSlot): Observable<any> {
    const timeSlot = {
      start: time.start.toISOString().replace('T', ' ').replace(':00.000Z', 'UTC'),
      end: time.end.toISOString().replace('T', ' ').replace(':00.000Z', 'UTC')
    };
    return this.http.post(`${environment.videoInterview}/${guid}/employee_booking/is_available`, timeSlot);
  }

  getApplicationInterviewsLibrary(): Observable<IInterview[]> {
    return this.getVideoInterviewsForCustomer(InterviewStatus.finished)
      .pipe(
        switchMap((interviews) => {
        const filteredInterviews = interviews
          .filter((interview) => !interview.createdVideo)
          .map((interview) => {
            interview.libraryVideo = this.baseVideoService.generateMixedFileName(interview.guid, false);
            return interview;
          });
        if (!filteredInterviews.length) {
          return of([]) as Observable<IInterview[]>;
        }
        const generatedFilenames = filteredInterviews.map(interview => interview.libraryVideo);
        return this.findInterviewsLibrary(generatedFilenames, filteredInterviews);
        })
      );
  }

  findInterviewsLibrary(generatedFilenames: string[], interviews: IInterview[]): Observable<IInterview[]> {
    return this.videoService.checkVideoList(generatedFilenames)
      .pipe(
        map((list: string[])=> {
          return interviews
            .filter((interview) => list.includes(interview.libraryVideo))
            .map((interview) => {
              interview.libraryVideo = this.baseVideoService.addPrefixToFileName(interview.libraryVideo);
              return interview;
            });
        }),
      );
  }

  getVideoInterviewsForOtherCustomer(userId: number): Observable<IInterview[]> {
    const endpoint = environment.customers;

    return this.http.get<IInterview[]>(`${endpoint}/${userId}/video_interviews`)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        map((interviewList: IInterview[]) => {
          const filteredInterviewList = interviewList.filter((interview) => {
            if (interview.status === InterviewStatus.cancelled || interview.status === InterviewStatus.finished) {
              this._interviewsInThePast.push(interview);
            }
            return (interview.status !== InterviewStatus.cancelled && interview.status !== InterviewStatus.finished);
          });
          return this.manageInteviewList(filteredInterviewList).filter((interview) => {
            if (interview.status === InterviewStatus.confirmed || interview.status === InterviewStatus.created) {
              const dateNow = new Date();
              const checkIfNotPast = interview.status === InterviewStatus.confirmed ?
                                      interview.timeSlot.end.getTime() > dateNow.getTime() :
                                      interview.timeSlots.findIndex(timeSlot => timeSlot.end.getTime() > dateNow.getTime()) > -1;
              if (!checkIfNotPast) {
                this._interviewsInThePast.push(interview);
              }
              return checkIfNotPast;
            }
            return true;
          });
        }),
        tap(() => this._inrerviewsInThePast$.next(this._interviewsInThePast))
      );
  }

  getAllCustomersPerTimeslot(videoInterviews: IInterview[]): Observable<IInterview[]> {
    if (!videoInterviews.length) {
      return of([]);
    }

    const requests$ = videoInterviews
      .map((videoInterview: IInterview) => this.checkAvailableCustomers(videoInterview));

    return forkJoin(requests$)
      .pipe(
        map((availableCustomers) => videoInterviews.map((interview, index) => {
          interview.availableCustomers = availableCustomers[index];
          return interview;
        })),
      );
  }

  checkAvailableCustomers(videoInterview: IInterview): Observable<AvailableCustomerPerTimeslot[]>{
    if (videoInterview.status === InterviewStatus.confirmed) {
      return this.getAvailableCustomersPerTimeslot(videoInterview.timeSlot, videoInterview);
    }

    const requests$ = videoInterview.timeSlots.filter((timeslot: TimeSlot) => timeslot.start > new Date() )
      .map((timeSlot: TimeSlot) => this.getAvailableCustomersPerTimeslot(timeSlot, videoInterview));

    return forkJoin(requests$)
      .pipe(
        map((availableCustomers) => {
          const availableCustomersIntersection = availableCustomers.shift().filter((availableCustomer: AvailableCustomerPerTimeslot) => {
            return availableCustomers.every((customersToCompare: AvailableCustomerPerTimeslot[]) => {
                return customersToCompare.some((customerToCompare) => customerToCompare.guid === availableCustomer.guid);
            });
          });
          return availableCustomersIntersection;
        }),
      );

  }

  getAvailableCustomersPerTimeslot(timeSlot: TimeSlot, videoInterview: IInterview): Observable<AvailableCustomerPerTimeslot[]> {
    let params = new HttpParams();
    const { start, end } = timeSlot;
    params = params.append('start', dateToUTCString(start));
    params = params.append('end', dateToUTCString(end));
    return this.http.get<AvailableCustomerPerTimeslot[]>(
      `${environment.company}/${this.setupService.companyGuid}/employees_available`, {params})
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        map((availableCustomers: AvailableCustomerPerTimeslot[]) => {
          return availableCustomers.filter((availableCustomer) => {
            if (ENTERPRISE_ROLES.includes(availableCustomer.roles[0])) {
              return videoInterview.application.universalJob ? true : this.checkIsHiddenFrom(availableCustomer, videoInterview);
            } else {
              return this.checkJobEmployeeRelation(availableCustomer, videoInterview);
            }
          });
        })
      );
  }

  checkJobEmployeeRelation(availableCustomer: AvailableCustomerPerTimeslot, videoInterview: IInterview): boolean {
    const { job, universalJob } = videoInterview.application;
    const creatorGuiduserGuid = videoInterview.senderCustomer?.guid || videoInterview.senderEnterpriseManager?.guid;
    if (availableCustomer.guid === creatorGuiduserGuid) {
      return false;
    }
    if (!universalJob) {
      const checkBranch = availableCustomer.roles[0] === Roles.companyHR ?
                          true : availableCustomer.branches.some(branch => branch.id === job.branch.id);
      const checkIsHidden = this.checkIsHiddenFrom(availableCustomer, videoInterview);
      return checkBranch && checkIsHidden ? true: false;
    } else {
      return availableCustomer.allowedUniversalJob;
    }

  }

  checkIsHiddenFrom(availableCustomer: AvailableCustomerPerTimeslot, videoInterview: IInterview): boolean {
    const { job } = videoInterview.application;
    return !job.hiddenFromCustomers?.find(({guid}: HiddenFromCoustomer) => availableCustomer.guid === guid) &&
           !job.hiddenFromEnterpriseManagers?.find(({guid}: HiddenFromCoustomer) => availableCustomer.guid === guid);
  }

  resetInterviewsInThePast(): void {
    this._interviewsInThePast = [];
  }

  acceptTermsAndConditions(guid: string): Observable<string> {
    return this.http.post<string>(`${environment.videoInterview}/${guid}/accept_terms_and_conditions`, {});
  }

  stopRecording(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });
    return this.http.post(`${this._apiServerUrl}/rest/interviewHigher/multirecord/terminate`, {uuid}, { headers, responseType: 'text', observe: 'response' });
  }

  startRecording(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });
    return this.http.post(`${this._apiServerUrl}/rest/interviewHigher/multirecord/startup`, {uuid}, { headers, responseType: 'text' });
  }

  finishInterview(uuid: string): Observable<any> {
    const request$ = this.isCustomerGroupInterview ? `${environment.groupVideoInterview}/${uuid}/finish` : `${environment.videoInterview}/${uuid}/finish`;
    return this.http.post<string>(request$, {});
  }
}
